

























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';
import MediaBackground from '@/components/wrappers/MediaBackground.vue';
import VideoBackground from '@/components/wrappers/VideoBackground.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: {
    InputSearchComponent,
    ButtonComponent,
    BCarousel,
    BCarouselSlide,
    VideoBackground,
    MediaBackground,
  },
  inheritAttrs: false,
})
export default class WideCarouselComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'WideCarouselWidgetStore';

  @Prop({ required: false, default: () => [] })
  private readonly slides!: Data[];

  private touchstartX = 0;

  private touchendX = 0;

  private lastStart = 0;

  private lastEnd = 0;

  private wideSlideElement: HTMLElement | null = null;

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    this.setEventListeners();
  }

  @Watch('isReadyToDisplay')
  setEventListeners(): void {
    if (this.isReadyToDisplay && this.slides.length > 0) {
      this.$nextTick(() => {
        this.wideSlideElement = document.getElementById('wide-carousel');
        if (this.wideSlideElement) {
          this.wideSlideElement.addEventListener('mousedown', this.onMouseDown);
          this.wideSlideElement.addEventListener('mouseup', this.onMouseUp);
        }
      });
    }
  }

  beforeDestroy(): void {
    if (this.wideSlideElement) {
      this.wideSlideElement.removeEventListener('mouseup', this.onMouseUp);
      this.wideSlideElement.removeEventListener('mousedown', this.onMouseDown);
    }
  }

  private onMouseDown(e: MouseEvent): void {
    this.touchstartX = e.screenX;
    this.lastStart = e.screenX;
  }

  private onMouseUp(e: MouseEvent): void {
    this.touchendX = e.screenX;
    this.lastEnd = e.screenX;
    if (this.touchendX < this.touchstartX) {
      (this.$refs.wideCarousel as BCarousel).next();
    }
    if (this.touchendX > this.touchstartX) {
      (this.$refs.wideCarousel as BCarousel).prev();
    }
    this.touchstartX = 0;
    this.touchendX = 0;
  }

  private onSearch(slide: Data, payload: { query: string }): void {
    if (payload.query) {
      const searchRoute = slide.searchUrl as string;
      if (searchRoute.includes('%search%')) {
        this.$router.push(searchRoute.replace('%search%', `search=${payload.query}`));
      } else {
        this.$router.push({
          ...this.$router.resolve(searchRoute).location,
          ...{ query: { search: payload.query } },
        });
      }
    }
  }

  private handleClick(route: string): void {
    if (route && this.lastEnd === this.lastStart) {
      let external = route.startsWith('//');
      if (external) {
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code || '',
          '',
          StatLoggerActions.CLICK,
          route,
          -1,
          '',
          StatLoggerCategories.CAROUSEL,
          this.$i18n.locale,
        );
        window.open(route, '_blank');
      } else {
        try {
          const url = new URL(route);
          external = !!url.protocol;
          if (external) {
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code || '',
              '',
              StatLoggerActions.CLICK,
              route,
              -1,
              '',
              StatLoggerCategories.CAROUSEL,
              this.$i18n.locale,
            );
            window.open(route, '_blank');
          }
        } catch {
          let r = route;
          const matches = route.match(/(%[a-zA-Z-_]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          const { name } = this.$router.resolve(r).route;
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code || '',
            '',
            StatLoggerActions.CLICK,
            name || r,
            -1,
            '',
            StatLoggerCategories.CAROUSEL,
            this.$i18n.locale,
          );
          this.$router.push(r);
        }
      }
    }
  }
}
